#cat-news{
    ul.newsgrid{
        display: block;
        list-style-type: none;
        margin-left: -1em;
        margin-right: -1em;
        margin-top: 2em;

        li{
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
            position: relative;
            width: calc(33.333% - 2em);
            float: left;
            margin: 0 1em;
            padding-top: 0;
            margin-bottom: 2em;

            & > a:first-child{
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                position: absolute;
            }

            .image{
                width: 100%;
                height: 250px;
                margin: 0 auto;
                background-size: cover;
                position: relative;
                background-position: center;

                a{
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                }
            }
            .tekstcontent{
                padding: 2em;

                .date{
                    color: $grey;
                    font-size: 0.9em;
                    margin-bottom: 1.5em;
                    i{
                        font-size: 0.9em;
                        margin-right: 0.5em;
                    }
                }
                a.button{
                    color: $baseColor;
                    &:hover{
                        color: darken($baseColor, 10%);
                    }
                }
            }
        }
    }
}



@media screen and(max-width: 1200px){
    #cat-news{
        ul.newsgrid{
            li{
                width: calc(50% - 2em);
            }
        }
    }
}
@media screen and(max-width: 768px){
    #cat-news{
        ul.newsgrid{
            li{
                width: calc(100% - 2em);
                height: auto !important;

                h4, p{
                    height: auto !important;
                }
            }
        }
    }
}
