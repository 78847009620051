#wpadminbar{
    display: none;
}
*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-weight: lighter;
	height: auto;
    outline: none !important;
}
html, body{
	width: 100%;
	height: 100%;
	font-size: 17px;
	margin: 0;
	padding: 0;
    color: $fontColor;
    font-family: "Open Sans";
    overflow-x: hidden;
    word-break: break-word;
}
body{
    padding: 0 2em;
}
.wrapper {
    min-height: calc(100% - 95px);
}
header{
    margin-left: -2em;
    margin-right: -2em;
}
a{
	text-decoration: none;
	outline: none;
	transition: all 0.5s;
  	color: inherit;
}
.container{
	width: 100%;
    max-width: 1500px;
	margin: 0 auto;
	position: relative;
	z-index: 10;

    &.small{
        max-width: 1000px;
    }
}
section{
    padding: 3em 0 ;
}
p{
  	margin-bottom: 1em;

    &:last-child{
        margin-bottom: 0em;
    }
}
strong{
    font-family: 'MyriadPro-Bold';
}
.clearing{
	clear: both;
	display: block;
}
img{
    max-width: 100%;
}


/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: 5px 20px 20px 0;
}
.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}
.alignright {
    float:right;
    margin: 5px 0 20px 20px;
}
.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}
a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}
a img.alignnone {
    margin: 5px 20px 20px 0;
}
a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}
a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}
.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}
.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}
.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}
.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}
.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}



@media screen and(max-width: 768px){
    body{
        padding: 0 1em;
    }



    /* =WordPress Core
    -------------------------------------------------------------- */
    a img.alignright {
        display: none;
    }
    a img.alignnone {
        display: none;
    }
    a img.alignleft {
        display: none;
    }
    a img.aligncenter {
        display: none;
    }
    .wp-caption {
        display: none;
    }
    .wp-caption img {
        display: none;
    }
    .wp-caption p.wp-caption-text {
        display: none;
    }
}
