@font-face {
  font-family: 'MyriadPro-Regular';
  src:  url('fonts/MyriadPro-Regular.eot') format('embedded-opentype');
  src:  url('fonts/MyriadPro-Regular.otf')  format('opentype'),
	    url('fonts/MyriadPro-Regular.woff') format('woff'),
        url('fonts/MyriadPro-Regular.ttf')  format('truetype'),
        url('fonts/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadPro-Bold';
  src:  url('fonts/MyriadPro-Bold.eot') format('embedded-opentype');
  src:  url('fonts/MyriadPro-Bold.otf')  format('opentype'),
	    url('fonts/MyriadPro-Bold.woff') format('woff'),
        url('fonts/MyriadPro-Bold.ttf')  format('truetype'),
        url('fonts/MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
