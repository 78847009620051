// Base colors
$baseColor: #009FE3;
$fontColor: #404040;

$darkBlue: #21374B;
$white: #fff;
$black: #000;
$grey: #BABABA;
$lightgrey: #C7C7C7;
$backgrey: #F7F7F7;
$bordergrey: #E6E6E6;
$warninggreen: darken(#DFF2BF, 20%);
$warningred: darken(#FFD2D2, 20%);
