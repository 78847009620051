#header{
    padding: 8em 3em;
    background-size: cover;
    background-position: center;
    text-align: center;
    .content{
        padding: 2.5em 2em;
        background-color: rgba($white, 0.9);
        max-width: 600px;
        margin: 0 auto;

        p{
            font-family: 'MyriadPro-Bold';
            font-size: 1.3em;
        }

        a{
            background-color: $baseColor;
            color: $white;
            text-transform: uppercase;
            padding: 0.5em 1em 0.3em;
            display: inline-block;
            font-size: 0.9em;
            margin-top: 0.5em;
            font-family: "Open Sans";
            font-weight: 400;

            &:hover{
                background-color: darken($baseColor, 10%);
            }
        }
    }

    &.small{
        height: 300px;
        padding: 0;
    }
}


#diensten{
    max-width: 1500px;
    margin: 0 auto;
    .bx-wrapper {
        box-shadow: none;
        border: none;
        background: #fff;
        margin-left: 15px;
        margin-right: -15px;

        .bx-viewport{
            padding: 1em;
            margin: -1em;
            & > ul{
                list-style: none;

                & > li{
                    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
                    padding: 3em 2em;
                    text-align: center;

                    strong{
                        display: block;
                        margin-bottom: 1em;
                    }
                    ul{
                        list-style: none;
                        // text-align: justify;
                        text-align: center;
                    }
                }
            }
        }
        .bx-pager.bx-default-pager a {
            background: $lightgrey;
        }
        .bx-pager.bx-default-pager a.active,
        .bx-pager.bx-default-pager a:focus,
        .bx-pager.bx-default-pager a:hover {
            background: $baseColor;
        }
    }
}


#update{
    #newsblock{
        width: 65.66%;
        float: left;

        h2{
            margin-bottom: 1em;
        }

        .article{
            background-color: $backgrey;
            position: relative;

            .left{
                height: 100%;
                min-height: 380px;
                width: 50%;
                float: left;
                background-size: cover;
                background-position: center;
                position: absolute;

                a{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
            }
            .right{
                width: 50%;
                float: right;
                padding: 2em;
                min-height: 380px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .content{
                .date{
                    color: $grey;
                    font-size: 0.9em;
                    margin-bottom: 1.5em;
                    i{
                        font-size: 0.9em;
                        margin-right: 0.5em;
                    }
                }
                a.button{
                    color: $baseColor;
                    &:hover{
                        color: darken($baseColor, 10%);
                    }
                }
            }
        }
    }
    #refblock{
        width: 32.33%;
        float: right;

        h2{
            margin-bottom: 1em;
        }

        .article{
            .equal{
                min-height: 380px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $baseColor;
                padding: 1.5em;
                color: $white;
            }
            .content{
                // text-align: center;
                // font-style: italic;

                h4{
                    text-align: right;
                    font-style: normal;
                }
            }
        }
    }
}


#klanten{
    .bx-wrapper {
        box-shadow: none;
        border: none;
        background: #fff;
        margin-left: 15px;
        margin-right: -15px;

        .bx-viewport{
            padding: 1em;
            margin: -1em;
            ul{
                list-style: none;
                li{
                    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
                    padding: 1em 2em;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    a{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                    }
                    img{
                        max-height: 100px;
                    }
                }
            }
        }
        .bx-pager.bx-default-pager a {
            background: $lightgrey;
        }
        .bx-pager.bx-default-pager a.active,
        .bx-pager.bx-default-pager a:focus,
        .bx-pager.bx-default-pager a:hover {
            background: $baseColor;
        }
    }
}


#socialmedia{
    .block{
        width: 49%;
        float: left;
        background-color: $backgrey;
        padding: 3em 2em;

        &:nth-child(2){
            float: right;
        }
    }
    h2{
        display: inline-block;
    }
    .timeline{
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        max-height: 600px;
        overflow-y: scroll;
    }
}


@media screen and(max-width: 1200px){
    #socialmedia{
        .block{
            width: 100%;
            padding: 3em 2em;

            &:nth-child(2){
                float: left;
            }
        }
        #facebook{
            margin-bottom: 3em;
        }
    }
}

@media screen and(max-width: 991px){
    #update{
        #newsblock{
            width: 100%;
            margin-bottom: 6em;
        }
        #refblock{
            width: 100%;
            float: left;
            .article .equal {
                min-height: 0;
                height: auto;
                padding: 3em 1.5em;
            }
        }
    }
}

@media screen and(max-width: 650px){
    #header {
        padding: 3em 1em;
        .content{
            padding: 2em 1em;

            p{
                font-size: 1.2em;
            }
        }

        &.small{
            display: none;
        }
    }
    #update{
        #newsblock{
            .article{
                .left{
                    height: 200px;
                    width: 100%;
                    position: relative;
                    min-height: 0;
                }
                .right{
                    width: 100%;
                    float: left;
                    padding: 2em;
                    min-height: 0;
                    height: auto !important;
                }
            }
        }
    }
}
