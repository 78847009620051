header{
    position: sticky;
    width: calc(100% + 4em);
    left: 1em;
    background-color: $white;
    z-index: 9999;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
    top: 0;
}

.topmenu{
    background-color: $baseColor;
    color: $white;
    padding: 0.2em 3.3em 0.5em;
    font-size: 0.9em;

    .topmenu-container{
        width: auto;
        display: inline-block;
        float: right;

        & > div{
            display: inline-block;
        }
        .other-sites{
            position: relative;
            margin-right: 1em;
            select{
                appearance: none;
                background-color: transparent;
                border: none;
                border-radius: 0;
                color: $white;
                font-size: 0.9em;
                padding-top: 0.1em;
                background-image: url('images/pijltje.png');
                background-repeat: no-repeat;
                background-position: 100% 70%;
                background-size: 9px;
                min-width: 100px;

                option{
                    color: $black;
                }
            }
            &:after{
                content: '|';
                position: absolute;
                top: 0;
                right: -1.2em;
            }
        }
        .language{
            .wpml-ls-legacy-list-horizontal{
                padding: 0;
                position: relative;

                a{
                    padding: 0 10px;
                }

                &:after{
                    content: '|';
                    position: absolute;
                    top: 0;
                    right: -0.7em;
                }
            }
        }
        ul.menu{
            list-style-type: none;
            & > li{
                display: inline-block;
                margin: 0 1.5em 0 1em;
                position: relative;
                &:after{
                    content: '|';
                    position: absolute;
                    top: 0;
                    right: -1.5em;
                }
                &:first-child{
                    margin-left: 0;
                }

                & > a{
                    position: relative;
                    &:after{
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 0;
                        height: 1px;
                        background-color: $white;
                        transition: all 0.5s;
                    }
                    &:hover:after, &.current-menu-item:after{
                        width: 100%;
                    }
                }

                &.current-menu-item a:after{
                    width: 100%;
                }
            }

            & > li.wpml-ls-menu-item{
                background-image: url('images/arrowdown.png');
                background-repeat: no-repeat;
                background-position: 100% center;
                background-size: 10px;
                ul.sub-menu{
                    display: none;
                    position: absolute;
                    background-color: $baseColor;
                    width: calc(100% + 3em);
                    min-width: 144px;
                    left: -1.5em;
                    list-style-type: none;
                    padding: 0.4em 0;
                    margin-top: 0.5em;

                    li{
                        padding: 0.1em 0.7em;
                    }
                }
                a{
                    padding-right: 1em;
                    img{
                        margin-right: 0.5em;
                    }
                }
            }
        }
        .socialbuttons{
            padding-left: 1.5em;
            a{
                margin-right: 1em;
                font-size: 0.9em;

                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

nav{
    padding: 1em 3em;
    .logo{
        width: 200px;
        display: block;
        float: left;
        margin: 0.9em 0;
        img{
            display: block;
        }
    }

    .menuwrapper{
        max-width: calc(100% - 200px);
        float: right;
        padding: 1.5em 0 0;

        .mainmenu{
            display: inline-block;
        }
        ul.menu{
            width: auto;
            list-style-type: none;
            display: inline-block;
            float: right;
            & > li{
                display: inline-block;
                margin: 0 1.5em;
                a{
                    font-size: 1.1em;
                    text-transform: uppercase;
                    position: relative;
                    padding: 0;
                    font-weight: normal;

                    &:after{
                        content: '';
                        position: absolute;
                        height: 3px;
                        width: 0px;
                        left: 0;
                        bottom: -0.4em;
                        background-color: $baseColor;
                        transition: all 0.5s;
                    }
                }
                &:hover > a, &.current-menu-item > a, &.current-menu-parent > a, &.current-page-ancestor > a, &.current-category-ancestor > a{
                    &:after{
                        width: 100%;
                    }
                }


                &.menu-item-has-children{
                    & > a{
                        padding-right: 1em;
                        position: relative;

                        &:before{
                            content: "\f078";
                            font-family: FontAwesome;
                            font-style: normal;
                            font-weight: normal;
                            text-decoration: inherit;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            font-size: 0.6em;
                            right: 0;
                        }
                    }
                }


                ul.sub-menu{
                    position: absolute;
                    background: #fff;
                    max-width: 300px;
                    margin-top: 1.7em;
                    padding: 0.5em;
                    display: none;
                    z-index: -1;
                    margin-left: -1.3em;

                    li{
                        display: block;
                        margin: 0.5em 0.9em;

                        a{
                            opacity: 0;
                            transition: all 0s;

                            &:hover{
                                color: $baseColor;
                            }
                        }
                    }
                }

                &.open ul.sub-menu li a{
                    opacity: 1;
                    transition: all 0.5s;
                }


                &.counter{
                    position: relative;

                    .bol{
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        background-color: $baseColor;
                        position: absolute;
                        color: $white;
                        font-size: 0.7em;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        right: -1.5em;
                        top: -0.5em;
                    }
                }
            }
            & > li:first-child{
                margin-left: 0;
            }

            & > li:last-child{
                margin-right: 0;
            }
        }
    }

    #trigger{
        display: none;
        width: 27px;
        height: 20px;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        float: right;
        margin-top: 2em;

        span{
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
            margin-top: 1px;
            background: $black;
        }
        span:nth-child(1) {
            top: 0px;
        }
        span:nth-child(2), span:nth-child(3) {
            top: 7px;
        }
        span:nth-child(4) {
            top: 14px;
        }
    }
    #trigger.open{
        span:nth-child(1) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
        span:nth-child(2) {
            transform: rotate(45deg);
        }
        span:nth-child(3) {
            transform: rotate(-45deg);
        }
        span:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
    }
}




@media screen and (max-width: 1550px){
    nav{
        .menuwrapper{
            ul{
                li{
                    display: inline-block;
                    margin: 0 1.3em;
                    &.menu-item-has-children{
                        & > a{
                            padding-right: 1em;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1400px){
    nav{
        .menuwrapper{
            ul{
                li{
                    display: inline-block;
                    margin: 0 0.9em;
                }
            }
        }
    }
}

@media screen and (min-width: 1301px){
    nav .menuwrapper {
        display: block !important;
    }
}

@media screen and(max-width: 1300px){
    nav{
        .menuwrapper {
            display: none;
            width: 100%;
            padding: 0;
            padding-top: 1.5em;
            max-width: none;
            ul{
                width: 100% !important;
                display: block;
                float: none;
                background-color: $white;
                li{
                    width: 100%;
                    margin: 0;
                    display: block;
                    text-align: center;
                    padding: 0.5em 0;
                    margin: 0 !important;

                    &.menu-item-has-children{
                        ul.sub-menu{
                            position: relative;
                            padding: 0.5em 1em;
                            box-shadow: none;
                            margin-top: 0;
                            z-index: auto;
                            max-width: none;
                            width: 100%;
                            margin-left: 0;
                            background-color: transparent;

                            li{
                                padding: 0.1em 0em;
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            .mainmenu{
                display: block;
                width: 100%;
            }
        }
        #trigger{
            display: block;
        }
    }
}


@media screen and(max-width: 600px){
    .topmenu{
        .topmenu-container{
            width: 100%;
            display: block;
            & > div{
                width: 100%;
                display: block;
                float: none;
                max-width: 400px;
                margin: 0 auto;
            }
            .other-sites{
                width: 100%;
                max-width: none;
                &:after{
                    display: none;
                }
            }
            .language{
                width: calc(50% - 2em);
                float: left;
                margin-left: -10px;
                .wpml-ls-legacy-list-horizontal{
                    &:after{
                        display: none;
                    }
                }
            }
            ul.menu{
                & > li{
                    width: calc(50% - 2em);
                    margin: 0;
                    padding: 0;
                    float: left;

                    &:nth-child(2n+1){
                        margin-right: 2em;

                        &:after{
                            right: -2em;
                        }
                    }
                    &:nth-child(2n){
                        margin-left: 2em;

                        &:after{
                            display: none;
                        }
                    }
                }
            }
            .socialbuttons{
                margin-left: 2em;
                max-width: calc(50% - 2em);
                margin: 0;
                padding: 0;
                float: right;
                width: auto;
            }
        }
    }
}
