h1{
    font-size: 1.5em;
    // font-family: 'MyriadPro-Bold';
    margin-bottom: 0.5em;
    word-break: break-word;
}
h2{
    font-size: 1.25em;
    text-transform: uppercase;
    font-family: 'MyriadPro-Bold';
    margin-bottom: 0.8em;
    padding-bottom: 0.2em;
    position: relative;
    text-align: left;

    &:after{
        content: '';
        position: absolute;
        height: 5px;
        width: 50px;
        left: 0;
        bottom: 0;
        background-color: $baseColor;
    }
}
h3{
    font-size: 1.15em;
    text-transform: uppercase;
    font-family: 'MyriadPro-Bold';
    padding-bottom: 0.2em;
}
h4{
    font-family: 'MyriadPro-Bold';
    font-size: 1.05em;
    padding-bottom: 0.2em;
}

.titleCenter{
    width: 100%;
    text-align: center;

    h2{
        display: inline-block;
    }
}




@media screen and(max-width: 768px){
    h1{
        font-size: 1.4em;
    }
}
@media screen and(max-width: 650px){
    h1{
        font-size: 1.3em;
    }
}
