#references{
    ul{
        list-style: none;
        margin-left: -1em;
        margin-right: -1em;
        margin-top: 0.8em;

        li{
            padding: 2em;
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
            width: calc(33.33% - 2em);
            float: left;
            margin: 0 1em;
            margin-bottom: 2em;

            p{
                // font-style: italic;
                text-align: left;
            }
            h4{
                font-size: 0.95em;
                text-align: right;
            }
        }
    }
}


@media screen and(max-width: 1200px){
    #references{
        ul{
            li{
                width: calc(50% - 2em);
            }
        }
    }
}
@media screen and(max-width: 768px){
    #references{
        ul{
            li{
                width: calc(100% - 2em);
            }
        }
    }
}
