#contactpagina{
    padding: 2em 0 0;

    .left{
        width: 35%;
        float: left;

        h3{
            font-size: 1.1em;
        }
    }

    .right{
        width: 55%;
        float: right;

        form{
            p{
                font-size: 0.9em;
                font-family: 'MyriadPro-Bold';
                text-align: center;
            }
            input, textarea{
                appearance: none;
                border: none;
                padding: 0.5em;
                font-size: 0.9em;
                background-color: $backgrey;
                width: 100%;
                float: none;
                display: block;
                margin-bottom: 1em;
                border-radius: 0;
            }
            textarea{
                height: 150px;
            }
            input[type="submit"]{
                width: auto;
                float: right;
                background-color: $baseColor;
                color: $white;
                text-transform: uppercase;
                padding: 0.8em 1.5em 0.5em 1.5em;
                font-family: 'MyriadPro-Bold';
                cursor: pointer;
                transition: all 0.5s;
                border-radius: 0;

                &:hover{
                    background-color: darken($baseColor, 10%);
                }
            }
        }
    }
}

#map{
    width: 100%;
    height: 350px;
    margin-top: 5em;
}



@media screen and(max-width: 786px){
    #contactpagina{
        .left{
            width: 100%;
            float: none;
            text-align: center;
            max-width: 400px;
            margin: 0 auto;
            display: block;
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
            padding: 2em 0 1.5em 0;
            margin-bottom: 5em;
        }

        .right{
            width: 100%;
            float: none;
        }
    }
}
