footer{
    background-color: $baseColor;
    padding: 1em 2em 0.7em;
    margin-top: 3em;
    color: $white;
    text-align: center;
    width: calc(100% + 5em);
    margin-left: -2.5em;
    margin-right: -2.5em;
    font-size: 0.9em;
}
