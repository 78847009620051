#single{
    a.back{
        margin-bottom: 1em;
        font-size: 0.9em;
        display: inline-block;
        color: $grey;

        &:hover{
            color: $baseColor;
        }
    }

    .container{
        max-width: 1000px;
        .block{
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.10);
            padding: 2em;

            .image{
                margin-bottom: 2em;
                img{
                    max-width: 100%;
                    width: 100%;
                    margin: 0 auto;
                }
            }

            .date{
                color: $grey;
                font-size: 0.9em;
                margin-bottom: 1.5em;
                i{
                    font-size: 0.9em;
                    margin-right: 0.5em;
                }
            }
        }
    }
}

#single.jobs{
    padding-top: 1em;
}
