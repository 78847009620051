#tekstpagina{
    ul.projects{
        margin-top: 5em;
        margin-bottom: calc(-3em - 3%);
        list-style: none;
        width: 100%;

        li{
            width: calc(33.33% - 2%);
            margin-right: 3%;
            margin-bottom: 3%;
            float: left;
            height: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            transition: all 0.5s;

            .layer{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                //background-color: rgba($baseColor, 0.5);
                z-index: 0;
            }
            .image{
                position: absolute;
                width: 80%;
                height: 80%;
                left: 10%;
                top: 10%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                z-index: -1;
            }

            a{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 999;
            }

            .title{
                font-size: 1.8em;
                font-family: 'MyriadPro-Bold';
                word-break: break-word;
                text-transform: uppercase;
                z-index: 1;
                color: $white;
            }

            &:nth-child(3n){
                margin-right: 0;
            }
            &:hover{
                transform: scale(1.05);
            }
        }
    }
}


@media screen and(max-width: 991px){
    #tekstpagina{
        ul.projects{
            li{
                width: calc(50% - 1.5%);
                margin-right: 3%;

                &:nth-child(3n){
                    margin-right: 3%;
                }
                &:nth-child(2n){
                    margin-right: 0;
                }
            }
        }
    }
}
@media screen and(max-width: 750px){
    #tekstpagina{
        ul.projects{
            li{
                height: 200px;
                width: 96%;
                margin-left: 2% !important;
                margin-right: 2% !important;
            }
        }
    }
}
